
import { defineComponent } from "vue";
import useVoucherAttchmentSet from "@/hooks/payment/EntitySiPayout/useVoucherAttchmentSet";
// import Pagination from "@/components/Pagination.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import UploadDetail from "@/views/Payment/EntitySiPayout/Voucher/uploadDetail.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight - 458;
export default defineComponent({
  name: "VoucherAttachmentSet",
  components: {
    UploadDetail,
    // Pagination,
    TableWrapper,
    // EntitySelector,
  },
  props: {},
  setup() {
    const {
      searchParams,
      // entityRef,
      columnsData,
      tableData,
      doSearch,
      doReset,
      pageChange,
      sizeChange,
      pageSize,
      pageNumber,
      total,
      isUploadVisible,
      exportClick,
      uploadClick,
      closeUpload,
      refreshTable,
      entityList
    } = useVoucherAttchmentSet();
    // 获取Entity下拉列表
    doSearch();
    const handlePageChange = (page: number, pageSize: number) => {
      pageChange(page, pageSize);
    };
    const handleSizeChange = (page: number, pageSize: number) => {
      sizeChange(page, pageSize);
    };
    return {
      searchParams,
      // entityRef,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      isUploadVisible,
      exportClick,
      uploadClick,
      closeUpload,
      refreshTable,
      entityList
    };
  },
});
