import { ref, reactive } from "vue";
import * as API from "@/API/payment";
import downloadFile from '@/utils/rv/downloadFile'
import moment from 'moment';

type SearchParams = {
    dealerName: string;
    dealerCode: string;
    swtCode: string;
    bank: string;
    account: string;
    entity: string;
}

export interface SalesTableData {
    dealerName: string;
    dealerCode: string;
    swtCode: string;
    bank: string;
    account: string;
}

const useVoucherAttchmentSet = () => {
    // const entityRef = ref()
    const searchParams = reactive<SearchParams>({
        dealerName: '',
        dealerCode: '',
        swtCode: '',
        bank: '',
        account: '',
        entity: 'FBAC'
    })
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const columnsData = ref([
        { title: "Dealer Code", dataIndex: "dealerCode"},
        { title: "SWT Code", dataIndex: "swtCode"},
        { title: "Dealer Name", dataIndex: "dealerName"},
        { title: "开户银行", dataIndex: "bank"},
        { title: "银行账号", dataIndex: "account"}
    ])
    const tableData = ref<SalesTableData[]>()
    const getTableData = () => {
        const params = {
            dealerName: searchParams.dealerName,
            dealerCode: searchParams.entity === 'DENZA' ? searchParams.dealerCode : '',
            swtCode: searchParams.entity === 'FBAC' ? searchParams.swtCode : '',
            bank: searchParams.bank,
            account: searchParams.account,
            entity: searchParams.entity,
            pageNumber: pageNumber.value,
            pageSize: pageSize.value
          }
        API.getVoucherList(params).then(data => {
            tableData.value = data.content
            total.value = data.totalElements
        })
        console.log('获取表格数据')
    }
    const doSearch = () => {
        searchParams.dealerName = searchParams.dealerName ? searchParams.dealerName : ""
        searchParams.dealerCode = searchParams.dealerCode ? searchParams.dealerCode : ""
        searchParams.swtCode = searchParams.swtCode ? searchParams.swtCode : ""
        searchParams.bank = searchParams.bank ? searchParams.bank : ""
        searchParams.account = searchParams.account ? searchParams.account : ""
        searchParams.entity = searchParams.entity ? searchParams.entity : ""
        pageNumber.value = 1
        if (searchParams.entity === 'FBAC') {
            columnsData.value = [
                // { title: "Dealer Code", dataIndex: "dealerCode"},
                { title: "SWT Code", dataIndex: "swtCode"},
                { title: "Dealer Name", dataIndex: "dealerName"},
                { title: "开户银行", dataIndex: "bank"},
                { title: "银行账号", dataIndex: "account"}
            ]
        } else {
            columnsData.value = [
                { title: "Dealer Code", dataIndex: "dealerCode"},
                // { title: "SWT Code", dataIndex: "swtCode"},
                { title: "Dealer Name", dataIndex: "dealerName"},
                { title: "开户银行", dataIndex: "bank"},
                { title: "银行账号", dataIndex: "account"}
            ]
        }
        getTableData()
    }
    const doReset = () => {
        Object.assign(searchParams, {
            dealerName: '',
            dealerCode: '',
            swtCode: '',
            bank: '',
            account: '',
            entity: ''
        })
        // entityRef.value.reset()
        tableData.value = []
        doSearch()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const isUploadVisible = ref<boolean>(false)
    const exportClick = () => {
        const params = {
            url: '/ptapi/voucher/export',
            method: 'post',
            params: {
                dealerName: searchParams.dealerName,
                dealerCode: searchParams.entity === 'DENZA' ? searchParams.dealerCode : '',
                swtCode: searchParams.entity === 'FBAC' ? searchParams.swtCode : '',
                bank: searchParams.bank,
                account: searchParams.account,
                entity: searchParams.entity,
                pageNumber: pageNumber.value,
                pageSize: pageSize.value
            },
            fileName: searchParams.entity + '_' + moment().format('YYYYMMDD') + '.xlsx'
        }
        downloadFile(params)
    }
    const uploadClick = () => {
        isUploadVisible.value = true
    }
    const closeUpload = (param: { isUploadVisible: boolean }) => {
        isUploadVisible.value = param.isUploadVisible
    }
    const refreshTable =()=>{
        closeUpload({ isUploadVisible: false })
        getTableData()
    }
    const entityList = ref([{
        entityName: 'FBAC'
    }, {
        entityName: 'DENZA'
    }])
    return {
        entityList,
        searchParams,
        // entityRef,
        columnsData,
        tableData,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        isUploadVisible,
        exportClick,
        uploadClick,
        closeUpload,
        refreshTable
    }
}
export default useVoucherAttchmentSet